
          $env: 'production';
          @import '/var/www/src/styles/utils/index';
        





































































































































































































.number {
  font-size: calc(0.2vw + 18px);
}

.boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 35rem));
  grid-gap: 3.5rem;
}

.box__amount {
  line-height: 1.2;
}
